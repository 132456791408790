import React, { useEffect, useRef, useState } from 'react'
import Container from '../../Components/Container'
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import toast, { Toaster } from 'react-hot-toast';


const Profile = () => {


    const passwordRef = useRef(null)
    const [open,setOpen]= useState(false)
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')

    const [newPassword, setNewPassword] = useState('')
    const [newPasswordAgain, setNewPasswordAgain] = useState('')


    useEffect(() => {
        const formdata = new FormData()
        formdata.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL+'profile.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(data => {
            console.log(typeof data)
            setName(data[0])
            setPassword(data[1])
        })
    }, [])

    const sumbitHandel = () => {
        const formdata = new FormData()
        formdata.append('action', 'update')
        
        formdata.append('password', password)
        formdata.append('oldPassword', newPassword)
        formdata.append('oldPasswordAgain', newPasswordAgain)
        fetch(process.env.REACT_APP_BASE_URL+'profile.php', {
            method: 'POST',
            body: formdata
        }).then(res=>res.json())
        .then(data => {
            if(data.status != 400){
                toast.success(data.message)
            }else{
                toast.error(data.message)
            }
        })
    }


    const toggleHandeler = (e) => {
        if (e.target.classList.contains('close')) {
            setOpen(false)
        }
    }

    const showPassword = () => {
        passwordRef.current.type === 'password' ? passwordRef.current.type = 'text' : passwordRef.current.type = 'password'
        if(passwordRef.current.type === 'password'){
            document.getElementById('open').style.display='block'
            document.getElementById('close').style.display='none'
        }else{
            document.getElementById('open').style.display='none'
            document.getElementById('close').style.display='block'
        }
    }


    return (
        <Container>
            <Toaster position='top-center'></Toaster>
            {
                open && <div onClick={(e) => { toggleHandeler(e) }} className='w-full p-4 lg:p-0 close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                    <div className='bg-white rounded-md p-4 w-full lg:w-1/2 gap-6 flex items-start justify-start flex-col'>
                        <h1 className='text-2xl font-semibold text-gray-700'>Değişiklik onayla</h1>
                        
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='password' className='text-gray-700 w-28 whitespace-nowrap'>Şifre</label>
                            <input onChange={(e)=>{setNewPassword(e.target.value)}} value={newPassword} type='password' placeholder='Şifre' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='passwordAgain' className='text-gray-700 w-28 whitespace-nowrap'>Şifre Tekrar</label>
                            <input onChange={(e)=>{setNewPasswordAgain(e.target.value)}} value={newPasswordAgain} type='password' placeholder='Şifre Tekrar' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        
                        <div className='w-full flex items-center justify-end'>
                            <button onClick={()=>{sumbitHandel()}} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Kaydet</button>
                        </div>
                    </div>
                </div>
            }
            <div className='w-full flex items-center justify-start'>
                <h1 className='text-2xl font-semibold text-gray-700'>Profil Ayarları</h1>
            </div>
            <div className='w-full shadow-sm rounded-md shadow-black p-6 flex items-center justify-start gap-3 flex-col'>
                <span className='w-full flex lg:items-center items-start flex-col lg:flex-row lg:gap-4 justify-start gap-2 '>
                    <label htmlFor='username' className='w-20 lg:text-base text-xl  whitespace-nowrap'>Kullanıcı Adı</label>
                    <input type='text' onChange={(e)=>{setName(e.target.value)}} value={name}  id='username' className='p-2 border  border-gray-300 w-full rounded-md' />
                </span>
                <span className='w-full  flex lg:items-center items-start lg:flex-row flex-col justify-start lg:gap-4 gap-2 '>
                    <label htmlFor='password' className='w-20  lg:text-base text-xl whitespace-nowrap'>Şifre</label>
                    <span className=' border border-gray-300 w-full rounded-md flex items-center justify-center'>
                        <input  onChange={(e)=>{setPassword(e.target.value)}} value={password} ref={passwordRef} type='password' id='password' className='w-full p-2  outline-none' />
                        <button onClick={() => { showPassword() }} className='flex items-center justify-center'>
                            <FaEye id='open' style={{display:'none'}}  className='border-l border-gray-300 p-2' size={40}></FaEye>
                            <FaEyeSlash id='close' className='border-l border-gray-300 p-2' size={40}></FaEyeSlash>
                        </button>
                    </span>
                </span>
                <span className='w-full flex items-center justify-end'>
                    <button onClick={()=>{setOpen(true)}} className='bg-indigo-400 whitespace-nowrap hover:bg-indigo-600  inline-block cursor-pointer text-white px-4 py-2 rounded-md'>Kaydet</button>
                </span>



            </div>
        </Container>
    )
}

export default Profile