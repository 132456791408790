import React, { useEffect, useState } from 'react'
import { Page, Text, View, Document } from '@react-pdf/renderer';
import { useLocation } from 'react-router-dom';
import { FiPrinter } from "react-icons/fi";
import { FaSave } from "react-icons/fa";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';






const ReportPage = () => {
    const location = useLocation();
    const [calc, setCalc] = useState([])
    const [counts, setCounts] = useState([])
    const [basket, setBasket] = useState()
    const [date, setDate] = useState()
    const [cost, setCost] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setCalc(location.state.calc)
        setCounts(location.state.counts)
        setBasket(location.state.basket)
        setDate(location.state.date)
        setCost(location.state.costs)

    }, [])


    const saveHandel = () => {

        setLoading(true); // Yükleniyor mesajını göster
        const input = document.getElementById('pdf-content');
        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgWidth = 210;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const pdf = new jsPDF('p', 'mm', 'a4');
            let position = 0;
            let heightLeft = imgHeight;

            const imgData = canvas.toDataURL('image/png');

            while (heightLeft > 0) {
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= 297;
                if (heightLeft > 0) {
                    position = heightLeft - imgHeight;
                    pdf.addPage();
                }
            }

            pdf.save(`${Object.values(date)}.pdf`);
            setLoading(false); // Yükleniyor mesajını gizle
        });
    };
    return (

        <>
            {loading && (
                <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
                    <div className="flex flex-col items-center">
                        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-blue-500"></div>
                        <p className="text-white mt-4">Yükleniyor...</p>
                    </div>
                </div>
            )}
            <Document id='pdf-content' className='p-12 w-full flex flex-col'>
                <Page size="A4" className='w-full flex items-start justify-start flex-col gap-6' >
                    <View className='w-full flex items-center justify-between print:justify-end text-lg p-2'>
                        <span className='flex items-center justify-center print:hidden gap-4'>
                            <button onClick={() => { window.print() }} className='bg-indigo-500 rounded-md p-4 text-white no-print flex items-center justify-center gap-3'>
                                Yazdır
                                <FiPrinter></FiPrinter>
                            </button>
                            <button onClick={() => { saveHandel() }} className='bg-green-500 rounded-md p-4 text-white no-print flex items-center justify-center gap-3'>
                                Kaydet
                                <FaSave></FaSave>
                            </button>
                        </span>
                        <Text>
                            Tarih:
                            {
                                date && Object.values(date)
                            }
                        </Text>
                    </View>
                    <View className='w-full flex items-center justify-between' >
                        {
                            calc.map(i => {
                                return (
                                    <View className='w-full flex-col flex  '>
                                        <View className='w-full flex items-center justify-between  border-4  '>
                                            {Object.keys(i).map(item => {
                                                return (
                                                    <Text className='text-2xl bg-slate-50 font-bold text-black border-x w-full text-center p-6'>{item}</Text>
                                                )
                                            })}
                                        </View>
                                        <View className='w-full flex items-center justify-between  border  '>
                                            {Object.values(i).map(item => {
                                                return (
                                                    <Text className='text-2xl text-black border-x w-full text-center p-6'>{parseInt(item).toFixed(2)}TL</Text>
                                                )
                                            })}
                                        </View>

                                    </View>
                                )
                            })
                        }

                    </View>
                    <View className='w-full flex items-center justify-between' >
                        {
                            counts && counts.map(i => {
                                return (
                                    <View className='w-full flex-col flex  '>
                                        <View className='w-full flex items-center justify-between  border-4  '>
                                            {Object.keys(i).map(item => {
                                                return (
                                                    <Text className='text-2xl bg-slate-50 font-bold text-black border-x w-full text-center p-6'>{item}</Text>
                                                )
                                            })}
                                        </View>
                                        <View className='w-full flex items-center justify-between  border  '>
                                            {Object.values(i).map(item => {
                                                return (
                                                    <View className='w-full flex items-center  border-slate-100 justify-center gap-4'>
                                                        <Text>
                                                            {
                                                                Object.keys(item).map(x => {
                                                                    return (
                                                                        <Text className='font-bold flex flex-col'>
                                                                            {x}:
                                                                        </Text>
                                                                    )
                                                                })
                                                            }
                                                        </Text>
                                                        <Text>
                                                            {
                                                                Object.values(item).map(x => {
                                                                    return (
                                                                        <Text className='flex flex-col'>
                                                                            {x}
                                                                        </Text>
                                                                    )
                                                                })
                                                            }
                                                        </Text>
                                                    </View>

                                                )
                                            })}
                                        </View>

                                    </View>
                                )
                            })
                        }

                    </View>

                    <View className='w-full'>
                        <View class="relative overflow-hidden shadow-md w-full ">
                            <table class="w-full">
                                <thead class=" w-full bg-[#6b7280] text-[#e5e7eb]" >
                                    <tr>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Ürün</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Tür</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Tutar</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Tarih</td>

                                    </tr>
                                </thead>
                                <tbody class="bg-white text-gray-500" >


                                    {
                                        cost && cost.map(item => {
                                            return (
                                                <tr class="py-3">
                                                    {
                                                        Object.values(item).map(i => {
                                                            if (i != 'NaN') {
                                                                return (
                                                                    <td class="py-3 border text-center  p-4" >{i}</td>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tr>

                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </View>
                    </View>
                    <View className='w-full'>
                        <View class="relative overflow-hidden shadow-md w-full ">
                            <table class="w-full">
                                <thead class=" w-full bg-[#6b7280] text-[#e5e7eb]" >
                                    <tr>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Ürün</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Adet</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Tutar</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Tarih</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Saat</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Kullanıcı</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Kategori</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">İD</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Fiyat</td>
                                        <td class="py-2 border text-center  p-4" contenteditable="true">Maliyet</td>
                                    </tr>
                                </thead>
                                <tbody class="bg-white text-gray-500" >


                                    {
                                        basket && basket.map(item => {
                                            return (
                                                <tr class="py-3">
                                                    {
                                                        Object.values(item).map(i => {
                                                            if (i != 'NaN') {
                                                                return (
                                                                    <td class="py-3 border text-center  p-4" >{i}</td>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </tr>

                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </View>
                    </View>

                </Page>
            </Document>
        </>


    )
}

export default ReportPage