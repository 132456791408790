import React, { useEffect } from 'react'
import { useState } from 'react';
import Container from '../../Components/Container'
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { Chart } from "react-google-charts";








const Report = () => {

    const [day,setDay]=useState(false)
    const [days, setDays] = useState([])
    const [data, setData] = useState([])
    const [cat, setCat] = useState([])
    const [selectedCat, setSelectedCat] = useState()
    const [store, setStore] = useState([])
    const [selectedStore, setSelectedStore] = useState([])
    const [price, setPrice] = useState(0)
    const [maliyet, setMaliyet] = useState(0)
    const [selectedType, setSelectedType] = useState()
    const [selectedMount, setSelectedMount] = useState(1)
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [sales, setSales] = useState([])
    const [retur, setReturn] = useState(0)
    const [z, setZ] = useState(0)
    const [costs, setCosts] = useState([])
    const navigate = useNavigate()


    const [totalR, settotalR] = useState([])
    const [totalZ, settotalz] = useState([])
    const [totalG, settotalG] = useState()


    const [detailS, setDeatilS] = useState(0)
    const [detailR, setDeatilR] = useState(0)
    const [detailZ, setDeatilZ] = useState(0)
    const [detailP, setDeatilP] = useState(0)
    const [detailC, setDeatilC] = useState(0)

    const startYear = 1900;
    const endYear = new Date().getFullYear();
    const years = [];

    const groupSalesData = (data) => {
        const groupedData = data.reduce((acc, sale) => {
            const { name, count, price, date, time, user, category, id, cost } = sale;
            if (!acc[name]) {
                acc[name] = { name, totalQuantity: 0, totalPrice: 0, date, time, user, category, id, price, cost };
            }
            acc[name].totalQuantity += parseInt(count, 10);
            acc[name].totalPrice += parseInt(price, 10) * parseInt(count, 10);
            return acc;
        }, {});

        return Object.values(groupedData);
    };



    const handleChange = (event) => {
        setSelectedYear(event.target.value);
    };


    for (let year = startYear; year <= endYear; year++) {
        years.push(year);
    }

    const types = [
        { name: 'Satış', value: 1 },
        { name: 'İade', value: 0 },
        { name: 'Zayiyat', value: 2 }
    ]

    const mounts = [
        { "name": "Ocak", "value": 1 },
        { "name": "Şubat", "value": 2 },
        { "name": "Mart", "value": 3 },
        { "name": "Nisan", "value": 4 },
        { "name": "Mayıs", "value": 5 },
        { "name": "Haziran", "value": 6 },
        { "name": "Temmuz", "value": 7 },
        { "name": "Ağustos", "value": 8 },
        { "name": "Eylül", "value": 9 },
        { "name": "Ekim", "value": 10 },
        { "name": "Kasım", "value": 11 },
        { "name": "Aralık", "value": 12 }
    ]



    useEffect(() => {


        const selectCat = new FormData()
        selectCat.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'categories.php', {
            method: 'POST',
            body: selectCat
        }).then(res => res.json())
            .then(data => {
                setCat(data)
            })

        const selectStore = new FormData()
        selectStore.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'store.php', {
            method: 'POST',
            body: selectStore
        }).then(res => res.json())
            .then(data => {
                setStore(data)
            })




    }, [])

    const columns = [
        {
            name: 'No',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Kategori',
            selector: row => row.category,
            sortable: true,
        },
        {
            name: 'İsim',
            selector: row => row.name,
            sortable: true
        },
        {
            name: 'Tarih',
            selector: row => row.date,
            sortable: true,
        },
        {
            name: 'Fiyat',
            selector: row => row.price,
            sortable: true,
        },
        {
            name: 'Maliyet',
            selector: row => row.cost,
            sortable: true,
        },
        {
            name: 'Adet',
            selector: row => row.totalQuantity,
            sortable: true,
        },


    ];



    const selectMoundfunc = (e, value) => {



        setSelectedMount(value)

        const formdata = new FormData()
        formdata.append('action', 'selectByMount')
        formdata.append('mount', value)
        formdata.append('date', selectedYear)
        fetch(process.env.REACT_APP_BASE_URL + 'report.php', {
            method: 'POST',
            body: formdata
        })
            .then(res => res.json())
            .then(data => {

                setData(data)
                setPrice(data.reduce((acc, item) => acc + item.price * item.count, 0))
                setMaliyet(data.reduce((acc, item) => acc + Number(item.cost) * Number(item.count), 0))

                setSales(data.filter(item => item.type == 1).length)
                setReturn(data.filter(item => item.type == 0).length)
                setZ(data.filter(item => item.type == 2).length)

                const r = data.filter(item => item.type == 0)
                const z = data.filter(item => item.type == 2)

                settotalR(r.reduce((acc, item) => acc + Number(item.price) * Number(item.count), 0))
                settotalz(z.reduce((acc, item) => acc + Number(item.price) * Number(item.count), 0))
            })

        const selectCost = new FormData()
        selectCost.append('action', 'selectCost')
        selectCost.append('date', selectedYear)
        selectCost.append('mount', value)
        fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
            method: 'POST',
            body: selectCost
        })
            .then(res => res.json())
            .then(data => {
                setCosts(data)

                settotalG(data.reduce((acc, item) => acc + Number(item.amount), 0))
            })


        const selectDailyReport = new FormData()
        selectDailyReport.append('action', 'days')
        selectDailyReport.append('mount', value)
        selectDailyReport.append('date', selectedYear)
        fetch(process.env.REACT_APP_BASE_URL + 'report.php', {
            method: 'POST',
            body: selectDailyReport
        })
            .then(res => res.json())
            .then(data => {
                setDays(data)
            })


    }


    const detailSearch = () => {
        const formdata = new FormData()
        formdata.append('action', 'detailSearch')
        formdata.append('mount', selectedMount)
        formdata.append('date', selectedYear)
        formdata.append('categories', selectedCat)
        formdata.append('store', selectedStore)
        formdata.append('type', selectedType)
        fetch(process.env.REACT_APP_BASE_URL + 'report.php', {
            method: 'POST',
            body: formdata
        })
            .then(res => res.json())
            .then(data => {
                setData(data)
                setDeatilP(data.reduce((acc, item) => acc + item.price * item.count, 0))
                setDeatilC(data.reduce((acc, item) => acc + Number(item.cost) * Number(item.count), 0))

                setDeatilS(data.filter(item => item.type == 1).length)
                setDeatilR(data.filter(item => item.type == 0).length)
                setDeatilZ(data.filter(item => item.type == 2).length)


            })

    }


    const reportHandel = () => {

        

        navigate('/dashboard/reportPage', {
            state: {
                calc: [
                    {
                        'Satış': price,
                        'Maliyet': maliyet,
                        'Kar': price - maliyet,
                        'Gider':totalG,
                        'Net kazanç':price-maliyet-totalG

                    }

                ],
                counts: [
                    {
                        'Satış': {
                            'Tutar': parseInt(price).toFixed(2),
                            'Adet': sales
                        },
                        'İade': {
                            'Tutar': parseInt(totalR).toFixed(2),
                            'Adet': retur
                        },
                        'Zaiat': {
                            'Tutar': parseInt(totalZ).toFixed(2),
                            'Adet': z
                        }

                    }
                ],
                basket: groupSalesData(data),
                date: {
                    'date':day != false ? day : 0 +'.'+ String(selectedMount)+'.'+ String(selectedYear)
                },
                costs:costs


            }
        })

    }


    const getDayDetails = (day) => {
        

        setDay(day)
        const formdata = new FormData()
        formdata.append('action', 'selectDayDetails')
        formdata.append('date', day)
        fetch(process.env.REACT_APP_BASE_URL + 'report.php', {
            method: 'POST',
            body: formdata
        })
            .then(res => res.json())
            .then((data) => {

                setData(groupSalesData(data))

                setPrice(data.reduce((acc, item) => acc + item.price * item.count, 0))
                setMaliyet(data.reduce((acc, item) => acc + Number(item.cost) * Number(item.count), 0))

                setSales(data.filter(item => item.type == 1).length)
                setReturn(data.filter(item => item.type == 0).length)
                setZ(data.filter(item => item.type == 2).length)

                const r = data.filter(item => item.type == 0)
                const z = data.filter(item => item.type == 2)

                settotalR(r.reduce((acc, item) => acc + Number(item.price) * Number(item.count), 0))
                settotalz(z.reduce((acc, item) => acc + Number(item.price) * Number(item.count), 0))

            }

            )


        const selectCost = new FormData()
        selectCost.append('action', 'selectCostDaily')
        selectCost.append('date',day)
        fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
            method: 'POST',
            body: selectCost
        })
            .then(res => res.json())
            .then(data => {
                setCosts(data)
                settotalG(data.reduce((acc, item) => acc + Number(item.amount), 0))
            })

    }


  

    return (
        <Container>


            <div className='w-full flex items-start justify-between'>
                <h1 className='text-2xl font-semibold text-gray-700'>Raporlar</h1>
            </div>


            <div className='w-full flex items-start justify-between'>
                <span>
                    <label htmlFor="year">Yıl Seçiniz: </label>
                    <select id="year" value={selectedYear} onChange={handleChange}>
                        {years.map((year) => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </span>

                {
                    selectedMount + '.ay'
                }

                <button onClick={() => { reportHandel() }} className='w-32 rounded-md bg-green-600 text-white p-2'>
                    Raport oluştur
                </button>


            </div>
            {
                selectedYear && <span className='flex w-full items-start overflow-x-scroll justify-start scroll gap-4'>
                    {
                        mounts && mounts.map(item => {
                            return (
                                <button onClick={(e) => { selectMoundfunc(e, item.value) }} className='w-24 p-2 text-center bg-indigo-500 text-white rounded-md'>
                                    {
                                        item.name
                                    }
                                </button>
                            )
                        })
                    }
                </span>
            }

            <div className='w-full flex items-center justify-start bg-slate-100 shadow-black/40 shadow-sm p-2 gap-4 flex-wrap '>
                {
                    days && days.map((item, index) => {
                        const day = Object.values(item)
                        return (
                            <button onClick={() => { getDayDetails(String(day)) }} className='w-24 p-2 text-center bg-indigo-400 hover:bg-indigo-200 text-white rounded-md'>
                                {
                                    String(day)
                                }
                            </button>
                        )
                    })
                }
            </div>

            {
                <div className='w-full flex flex-col items-center rounded-md justify-center gap-4 p-4 bg-indigo-600 '>
                    <div className='w-full flex items-center justify-center gap-4'>

                        <span className='w-full bg-black/10 text-white rounded-md p-2  flex flex-col items-center justify-center'>
                            <p className='border-b border-white text-center w-full'>
                                Toplam Satış
                            </p>
                            <p className='py-2'>
                                {parseInt(price).toFixed(2)} TL
                            </p>
                        </span>
                    </div>
                    <div className='w-full flex items-center justify-center gap-4'>

                        <span className='w-full bg-black/10 text-white rounded-md p-2  flex flex-col items-center justify-center'>
                            <p className='border-b border-white text-center w-full'>
                                Toplam Kar
                            </p>
                            <p className='py-2'>
                                {parseInt(price - maliyet).toFixed(2)} TL
                            </p>
                        </span>
                    </div>
                    <div className='w-full flex items-center justify-center gap-4'>

                        <span className='w-full bg-black/10 text-white rounded-md p-2  flex flex-col items-center justify-center'>
                            <p className='border-b border-white text-center w-full'>
                                Toplam maliyet
                            </p>
                            <p className='py-2'>
                                {parseInt(maliyet).toFixed(2)} TL
                            </p>
                        </span>
                    </div>
                    <div className='w-full flex items-center justify-center gap-4'>

                        <span className='w-full bg-black/10 text-white rounded-md p-2  flex flex-col items-center justify-center'>
                            <p className='border-b border-white text-center w-full'>
                                Aylık Net Kazanç
                            </p>
                            <p className='py-2'>
                                {parseInt(price - maliyet - totalR - totalZ-  totalG).toFixed(2)} TL
                            </p>
                        </span>
                    </div>

                    <div className='w-full flex items-center justify-center gap-4'>
                        <span className='w-full bg-black/10 text-white  rounded-md p-2 flex flex-col items-center justify-center'>
                            <p className='border-b border-white w-full text-center'>
                                Satış adeti
                            </p>
                            <div className='w-full flex flex-col items-center justify-center'>
                                <span className='py-2 flex items-center justify-center gap-2'>
                                    <p>
                                        {
                                            sales
                                        }
                                    </p>
                                    <p>
                                        adet
                                    </p>
                                </span>
                                <span className='py-2 flex items-center justify-center gap-2'>
                                    <p>
                                        {
                                            parseInt(price).toFixed(2)
                                        }
                                    </p>
                                    <p>
                                        TL
                                    </p>
                                </span>


                            </div>
                        </span>
                        <span className='w-full bg-black/10 text-white rounded-md p-2  flex flex-col items-center justify-center'>
                            <p className='border-b border-white text-center w-full'>
                                İade adeti
                            </p>
                            <div className='w-full flex flex-col items-center justify-center'>
                                <span className='py-2 flex items-center justify-center gap-2'>
                                    <p>
                                        {
                                            retur
                                        }
                                    </p>
                                    <p>
                                        adet
                                    </p>
                                </span>
                                <span className='py-2 flex items-center justify-center gap-2'>
                                    <p>
                                        {
                                            parseInt(totalR).toFixed(2)
                                        }
                                    </p>
                                    <p>
                                        TL
                                    </p>
                                </span>


                            </div>
                        </span>
                        <span className='w-full bg-black/10 text-white rounded-md p-2  flex flex-col items-center justify-center'>
                            <p className='border-b border-white text-center w-full'>
                                Zayiyat adeti
                            </p>
                            <div className='w-full flex flex-col items-center justify-center'>
                                <span className='py-2 flex items-center justify-center gap-2'>
                                    <p>
                                        {
                                            z
                                        }
                                    </p>
                                    <p>
                                        adet
                                    </p>
                                </span>
                                <span className='py-2 flex items-center justify-center gap-2'>
                                    <p>
                                        {
                                            parseInt(totalZ).toFixed(2)
                                        }
                                    </p>
                                    <p>
                                        TL
                                    </p>
                                </span>


                            </div>
                        </span>
                    </div>
                </div>
            }

            {
                costs && <div className='w-full flex  flex-col items-center justify-start rounded-md overflow-hidden  '>
                    <div className='w-full bg-indigo-600 flex items-center justify-between p-4 text-white'>
                        <p className='  w-full text-center'>Sno</p>
                        <p className='  w-full text-center border-x border-white'>Tür</p>
                        <p className='  w-full text-center border-r border-white' >Tutar</p>
                        <p className='  w-full text-center'>Tarih</p>
                    </div>
                    <div className='w-full flex items-center justify-center flex-col  '>
                        {
                            costs && costs.map(item => {
                                return (
                                    <div className='w-full even:bg-indigo-100 odd:bg-indigo-50 p-2 flex items-center justify-between'>
                                        {
                                            Object.values(item).map(i => {
                                                return (<p className='text-center w-full'>
                                                    {i}
                                                </p>)
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                        <div className='w-full  bg-indigo-600 text-white flex items-end justify-end p-2'>
                            <span className=' flex items-end justify-center gap-1'>
                                Toplam Gider:
                                <p>
                                    {totalG} TL
                                </p>
                            </span>
                        </div>
                    </div>

                </div>
            }

            <div className='w-full flex gap-4 flex-col items-start justify-start'>
                <details className='w-full flex flex-col items-start justify-start gap-4'>

                    <span className='w-full flex items-center justify-between gap-4 '>
                        <label htmlFor='name' className='text-gray-700 w-24'>Depo</label>
                        <select multiple={false} value={selectedStore} onChange={(e) => { setSelectedStore(e.target.value) }} className='p-2 border w-full border-gray-300 rounded-md'>
                            <option value={0}>Depo Seçiniz</option>
                            {
                                store.map((store, index) => {
                                    return <option value={store.name} key={index}>{store.name}</option>
                                })
                            }
                        </select>
                    </span>
                    {
                        selectedStore != 0 && <span className='w-full mt-4  flex items-center justify-between gap-4 '>
                            <label htmlFor='name' className='text-gray-700 w-24'>Kategori </label>
                            <select multiple={false} value={selectedCat} onChange={(e) => { setSelectedCat(e.target.value) }} className='p-2 border w-full border-gray-300 rounded-md'>
                                <option>Kategori Seçiniz</option>
                                {
                                    cat.map((store, index) => {
                                        return <option value={store.name} key={index}>{store.name}</option>
                                    })
                                }
                            </select>
                        </span>
                    }
                    {
                        selectedStore != 0 && <span className='w-full mt-4  flex items-center justify-between gap-4 '>
                            <label htmlFor='name' className='text-gray-700 w-24'>Tür </label>
                            <select multiple={false} value={selectedType} onChange={(e) => { setSelectedType(e.target.value) }} className='p-2 border w-full border-gray-300 rounded-md'>
                                <option value={-1}>Tür Seçiniz</option>
                                {types.map((item, index) => (
                                    <option key={index} value={item.value}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>
                        </span>
                    }


                    {
                        selectedStore != 0 &&
                        <button onClick={() => { detailSearch() }} className='bg-indigo-500 mt-4 text-white px-4 py-2 rounded-md w-full'>Ara</button>
                    }
                    {
                        <div className='w-full mt-6 flex flex-col items-center rounded-md justify-center gap-4 p-4 bg-green-600 '>
                            <div className='w-full flex items-center justify-center gap-4'>
                                <span className='w-full bg-black/10 text-white  rounded-md p-2 flex flex-col items-center justify-center'>
                                    <p className='border-b text-sm whitespace-nowrap border-white w-full text-center'>
                                        Satış Tutarı
                                    </p>
                                    <p className='py-2'>
                                        {
                                            parseInt(detailP).toFixed(2)
                                        }
                                    </p>
                                </span>
                                <span className='w-full bg-black/10 text-white rounded-md p-2  flex flex-col items-center justify-center'>
                                    <p className='border-b text-sm whitespace-nowrap border-white text-center w-full'>
                                        Toplam maliyet Tutarı
                                    </p>
                                    <p className='py-2'>
                                        {parseInt(detailC).toFixed(2)}
                                    </p>
                                </span>
                            </div>
                            <div className='w-full flex items-center justify-center gap-4'>
                                <span className='w-full bg-black/10 text-white  rounded-md p-2 flex flex-col items-center justify-center'>
                                    <p className='border-b text-sm whitespace-nowrap border-white w-full text-center'>
                                        Satış adeti
                                    </p>
                                    <p className='py-2'>
                                        {
                                            detailS
                                        }
                                    </p>
                                </span>
                                <span className='w-full bg-black/10 text-white rounded-md p-2  flex flex-col items-center justify-center'>
                                    <p className='border-b text-sm whitespace-nowrap border-white text-center w-full'>
                                        İade adeti
                                    </p>
                                    <p className='py-2'>
                                        {
                                            detailR
                                        }
                                    </p>
                                </span>
                                <span className='w-full bg-black/10 text-white rounded-md p-2  flex flex-col items-center justify-center'>
                                    <p className='border-b text-sm whitespace-nowrap border-white text-center w-full'>
                                        Zayiyat adeti
                                    </p>
                                    <p className='py-2'>
                                        {
                                            detailZ
                                        }
                                    </p>
                                </span>
                            </div>
                        </div>
                    }
                </details>
            </div>
            <DataTable
                columns={columns}
                data={groupSalesData(data)}
                pagination
            />


            {/* <Chart
                chartType="PieChart"
                data={dataa}
                options={options}
                width={"100%"}
                height={"400px"}
            /> */}







        </Container>
    )
}

export default Report