import React from 'react'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';


const ProductCard = ({ item }) => {


    const role = window.localStorage.getItem('role')
    const navigate = useNavigate()
    

    const deleteHandler = (id) => {
        const formdata = new FormData()
        formdata.append('action', 'delete')
        formdata.append('id', id)
        fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
            method: 'POST',
            body: formdata
        })
            .then(res => res.json())
            .then(data => {
                if (data.status != 400) {
                    toast.success(data.message)
                }
                else {
                    toast.error(data.message)
                }
            })
    }


    const restoreHandler = (id) => {
        const formdata = new FormData()
        formdata.append('action', 'restore')
        formdata.append('id', id)
        fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
            method: 'POST',
            body: formdata
        })
            .then(res => res.json())
            .then(data => {
                if (data.status != 400) {
                    toast.success(data.message)
                } else {
                    toast.error(data.message)
                }
            })
    }


    const deleteItem =(id)=>{

        const formdata = new FormData();
        formdata.append('action','permaDelete')
        formdata.append('id',id)
        fetch(process.env.REACT_APP_BASE_URL+'product.php',
            {
                method:'POST',
                body:formdata
            }
        ).then(res=>res.json())
        .then(data=>console.log(data));
        


    }


    const navigateHandel =()=>{
        // /dashboard/barcode
        const b = item.barcode

        navigate('/dashboard/barcode',{state:item})

        
    }

    return (
        <div className='w-full bg-white p-4 rounded-md shadow-md'>
            <Toaster position='top-center'></Toaster>
            <img src={item.image != null ? item.image : 'https://placehold.co/600x400'} className='w-full h-48 object-cover rounded-md' alt="" />
            <div className='w-full flex items-center justify-between mt-2'>
                <h1 className='text-lg capitalize font-semibold text-gray-800'>{item.name}</h1>
                <h4 className='text-sm font-semibold text-gray-800'>{item.barcode}</h4>
                <p className='text-sm font-semibold text-gray-500'>{item.price} TL</p>
            </div>
            <div className='w-full flex flex-col items-start gap-2 justify-between mt-2'>
                <span className='w-full flex items-center justify-between'>
                    <p className='text-sm whitespace-nowrap w-full font-semibold text-gray-500'>{item.count} Adet Stokta</p>
                    {
                        item.status != 0 ? null : <button onClick={()=>{deleteItem(item.id)}} className=' w-56 p-3 flex items-center justify-center bg-red-500 rounded-md text-white'>Kalıcı olarak sil</button>
                    }
                </span>

                <div className='w-full flex items-center gap-4 justify-between'>
                    <Link to={`/dashboard/updateProduct/${item.id}`} className='bg-indigo-400 hover:bg-indigo-500 text-center text-white px-4 py-1 w-full rounded-md'>Düzenle</Link>
                    {
                        item.status != 0 ? <button onClick={() => { deleteHandler(item.id) }} className='bg-red-500 hover:bg-red-600 text-white px-4 py-1 w-full rounded-md'>Sil</button>
                            :
                            <button onClick={() => { restoreHandler(item.id) }} className='bg-green-500 text-white px-4 py-1 w-full rounded-md'>Geri yükle</button>
                    }
                </div>
                <div onClick={()=>{navigateHandel()}} className='w-full cursor-pointer hover:bg-indigo-500 flex items-center justify-center bg-indigo-400 rounded-md text-white p-2 '>
                    Barkod yazdır
                </div>
            </div>
        </div>
    )
}

export default ProductCard