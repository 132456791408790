import React, { useState, useRef, useCallback, useEffect } from 'react'
import Container from '../../Components/Container'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import { BarcodeScanner, useTorch } from "react-barcode-scanner"
import { FaCamera } from "react-icons/fa6";
import "react-barcode-scanner/polyfill"
import Webcam from "react-webcam";
import { BiSolidCameraOff } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { Switch } from '@headlessui/react'








const UpdateProduct = () => {


    const { id } = useParams()
    const [date, setDate] = useState()
    const [barcode, setBarcode] = useState('')
    const [imageSrc, setImageSrc] = React.useState(null);
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [height, setHeight] = useState('')
    const [width, setWidth] = useState('')
    const [length, setLength] = useState('')
    const [cost, setCost] = useState('')
    const [price, setPrice] = useState('')
    const [category, setCategory] = useState('')
    const [store, setStore] = useState('')
    const [cameraOpen, setCameraOpen] = useState(false)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [count, setCount] = useState(1)
    const [isSupportTorch, , onTorchSwitch] = useTorch()
    const [cat, setCat] = useState([])
    const [stores, setStores] = useState([])
    const [list, setList] = useState(false)

    const webcamRef = useRef(null);

    const [open, setOpen] = useState(false)

    const nextStep = () => {
        const max = 2
        if (selectedIndex === max) return
        setSelectedIndex(selectedIndex + 1)
    }
    const prevStep = () => {
        if (selectedIndex === 0) return
        setSelectedIndex(selectedIndex - 1)
    }

    useEffect(() => {
        const categories = new FormData()
        categories.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'categories.php', {
            method: 'POST',
            body: categories
        }).then(res => res.json()).then(data => {
            setCat(data)
        })

        const stores = new FormData()
        stores.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'store.php', {
            method: 'POST',
            body: stores
        }).then(res => res.json()).then(data => {
            setStores(data)
        })


    }, [])

    const options = {

        formats: [
            'ean_13',
            'code_128',
            'code_39',
            'code_93',
            'codabar',
            'ean_8',
            'itf',
            'qr_code',
            'upc_a',
            'upc_e',
        ]
    }

    const onCapture = (detected) => {
        if (detected) {
            setBarcode(detected.rawValue)
            alert(detected.rawValue)
            setOpen(false)
        }
    }

    const toggleHandeler = (e) => {
        e.preventDefault()
        if (e.target.classList.contains('close')) {
            setOpen(false)
        }
    }

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImageSrc(imageSrc);
    }, [webcamRef]);
    const videoConstraints = {
        facingMode: "environment"
    };

    const PanelBtn = ({ children }) => {
        return (
            <Tab as='span'>
                {({ hover, selected }) => (
                    <a className={selected ? 'bg-indigo-600 block text-sm  text-center lg:text-base px-6 py-2 text-white rounded-md' : 'bg-indigo-400  block text-center text-sm lg:text-base px-6 py-2 text-white rounded-md'}>{children}</a>
                )}
            </Tab>
        )
    }


    const sumbitHandel = () => {
        const formdata = new FormData()
        formdata.append('id', id)
        formdata.append('action', 'update')
        formdata.append('name', name)
        formdata.append('description', description)
        formdata.append('height', height)
        formdata.append('width', width)
        formdata.append('depth', length)
        formdata.append('count', count)
        formdata.append('cost', cost)
        formdata.append('price', price)
        formdata.append('category', category)
        formdata.append('store', store)
        formdata.append('barcode', barcode)
        formdata.append('image', imageSrc)
        formdata.append('list', list == true ? 1 : 0)
        formdata.append('date', date)

        fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                if (data.status != 400) {
                    toast.success(data.message)
                } else {
                    toast.error(data.message)
                }
            })
            .catch(e => console.error(e))

    }

    useEffect(() => {
        const formdata = new FormData()
        formdata.append('action', 'selectById')
        formdata.append('id', id)
        fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(data => {
            setName(data.name)
            setDescription(data.description)
            setHeight(data.height)
            setWidth(data.width)
            setLength(data.depth)
            setCount(data.count)
            setPrice(data.price)
            setCategory(data.category)
            setStore(data.store)
            setBarcode(data.barcode)
            setImageSrc(data.image)
            setCost(data.cost)
            setDate(data.date)
            data.list == 1 ? setList(true) : setList(false)
        }).catch(err => {
            console.log(err)
        })
    }, [])


    return (
        <Container>
            <Toaster position='top-center'></Toaster>
            <TabGroup selectedIndex={selectedIndex} onChange={setSelectedIndex} className='w-full flex flex-col items-start justify-start gap-4'>
                <TabList className='w-full flex items-center justify-start gap-6'>
                    <PanelBtn>Barkod Girişi</PanelBtn>
                    <PanelBtn>Fotograf Girişi</PanelBtn>
                    <PanelBtn>Ürün Bilgisi</PanelBtn>
                </TabList>
                <TabPanels className='w-full'>

                    <TabPanel className='w-full flex items-start flex-col justify-start h-auto gap-4 bg-slate-100 p-2 rounded-md'>
                        <div className='w-full flex items-center gap-2 justify-between'>
                            <input type='text' value={barcode} onChange={(e) => { setBarcode(e.target.value) }} placeholder='Barkod giriniz' className='w-full p-2 border border-gray-300 rounded-md' />

                            <button onClick={() => { setOpen(true) }} className='bg-indigo-500 flex group items-center justify-center gap-1 rounded-md text-white whitespace-nowrap h-10 px-4'>
                                <FaCamera />
                                <p className='w-0 group-hover:w-32 overflow-hidden transition-all'>
                                    Kamera ile oku
                                </p>
                            </button>
                        </div>
                        {
                            open && <div onClick={(e) => { toggleHandeler(e) }} className='w-full p-4 lg:p-12  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                                <div className='bg-white rounded-md lg p-4 lg:h-full w-full  gap-6 flex items-start justify-start flex-col'>
                                    <BarcodeScanner options={options} onCapture={onCapture} />
                                    <div className='w-full flex items-center gap-2 justify-between'>

                                        {isSupportTorch ? (
                                            <button className='bg-yellow-400 whitespace-nowrap text-black px-4 py-2 rounded-md' onClick={onTorchSwitch}>El Feneri</button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='w-full flex items-center justify-end'>
                            <button onClick={() => { nextStep() }} className='bg-green-500 w-full whitespace-nowrap text-white rounded-md p-2'>İleri</button>
                        </div>
                    </TabPanel>
                    <TabPanel className='w-full flex items-start gap-4 justify-start h-auto bg-slate-100 p-2 flex-col'>
                        {
                            !cameraOpen && <button className='w-full p-3 gap-4 flex items-center justify-center text-white bg-indigo-600 rounded-md' onClick={() => { setCameraOpen(true) }}>
                                <FaCamera size={24}></FaCamera>
                                <p>Kamera Aç</p>
                            </button>
                        }
                        {
                            cameraOpen && <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/webp"
                                screenshotQuality={0.3}
                                videoConstraints={videoConstraints}
                                width="100%"
                            />
                        }
                        {
                            cameraOpen && <div className='w-full flex flex-col items-center justify-between p-2 gap-4 rounded-md'>
                                <div className='w-full flex items-center md:flex-row justify-center flex-col gap-4'>
                                    <button className='w-full p-2 flex gap-4 items-center justify-center bg-green-400 text-white rounded-md' onClick={capture}>
                                        <FaCamera size={24}></FaCamera>
                                        <p>Çek</p>
                                    </button>
                                    <button className='w-full p-2 bg-red-600 text-white rounded-md flex items-center justify-center gap-4' onClick={() => { setCameraOpen(false) }}>
                                        <BiSolidCameraOff size={24}></BiSolidCameraOff>
                                        <p>Kapat</p>
                                    </button>
                                </div>
                                {
                                    imageSrc && <button className='w-full p-2 bg-red-600 rounded-md text-white flex items-center justify-center gap-4' onClick={() => { setImageSrc(null) }}>
                                        <FaTrash size={24}></FaTrash>
                                        <p>Sil</p>
                                    </button>
                                }

                            </div>
                        }
                        {imageSrc && (
                            <div>
                                <h2>Çekilen Fotoğraf:</h2>
                                <img className='w-full' src={imageSrc} alt="Çekilen fotoğraf" />
                            </div>
                        )}
                        <div className='w-full flex items-center gap-4 justify-end'>
                            <button onClick={() => { prevStep() }} className='bg-green-500 w-full whitespace-nowrap text-white rounded-md p-2'>Geri</button>
                            <button onClick={() => { nextStep() }} className='bg-green-500 w-full whitespace-nowrap text-white rounded-md p-2'>İleri</button>
                        </div>
                    </TabPanel>
                    <TabPanel className='w-full flex flex-col gap-4 items-start justify-start h-auto bg-slate-100 p-2 rounded-md'>
                        <span className='w-full flex items-start justify-between gap-4 '>
                            <label htmlFor='' className='text-gray-700 w-24'>Tarih</label>
                            {date}
                        </span>
                        <span className='w-full flex items-start justify-between gap-4 '>
                            <label htmlFor='name' className='text-gray-700 w-24'>Ürün Adı</label>
                            <input type='text' value={name} onChange={(e) => { setName(e.target.value) }} placeholder='Ürün Adı' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-start justify-between gap-4 '>
                            <label htmlFor='name' className='text-gray-700 w-24'>Ürün Açıklama</label>
                            <textarea type='text' value={description} onChange={(e) => { setDescription(e.target.value) }} placeholder='Ürün Adı' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-start justify-between gap-4 '>
                            <label htmlFor='name' className='text-gray-700 w-24'>Boyut</label>
                            <div className='w-full flex items-center justify-center gap-4'>
                                <input type='text' value={length} onChange={(e) => { setLength(e.target.value) }} placeholder='Derinlik' className='p-2 border w-full border-gray-300 rounded-md' />
                                <input type='text' value={height} onChange={(e) => { setHeight(e.target.value) }} placeholder='Yükseklik' className='p-2 border w-full border-gray-300 rounded-md' />
                                <input type='text' value={width} onChange={(e) => { setWidth(e.target.value) }} placeholder='Yükseklil' className='p-2 border w-full border-gray-300 rounded-md' />
                            </div>
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='name' className='text-gray-700 w-24'>Depo</label>
                            <select value={store} onChange={(e) => { setStore(e.target.value) }} className='p-2 border w-full border-gray-300 rounded-md'>
                                <option>Depo Seçiniz</option>
                                {
                                    stores.map((store, index) => {
                                        return <option value={store.name} key={index}>{store.name}</option>
                                    })
                                }
                            </select>
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='categories' className='text-gray-700 w-24'>Kategori</label>
                            <select value={category} onChange={(e) => { setCategory(e.target.value) }} className='p-2 border w-full border-gray-300 rounded-md'>
                                <option>Kategori Seçiniz</option>
                                {
                                    cat.map((category, index) => {
                                        return <option value={category.name} key={index}>{category.name}</option>
                                    })
                                }
                            </select>
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='name' className='text-gray-700 w-24'>Stok Adeti</label>
                            <input type='number' value={count} onChange={(e) => { setCount(e.target.value) }} placeholder='Stok Adeti' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='price' className='text-gray-700 w-24'>Fiyat</label>
                            <input type='number' value={price} onChange={(e) => { setPrice(e.target.value) }} placeholder='Fiyat' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='cost' className='text-gray-700 w-24'>Alış Fiyatı</label>
                            <input type='number' value={cost} onChange={(e) => { setCost(e.target.value) }} placeholder='Alış Fiyatı' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-center justify-center'>
                            <label htmlFor='list' className='text-gray-700 w-full'>
                                Hızlı ürün listesine ekle
                            </label>
                            <Switch
                                checked={list}
                                id='list'
                                onChange={setList}
                                className={`${list ? 'bg-indigo-400' : 'bg-gray-200'
                                    } relative inline-flex items-center h-6 rounded-full w-11`}
                            >

                                <span
                                    className={`${list ? 'translate-x-6' : 'translate-x-1'
                                        } inline-block w-4 h-4 transform bg-white rounded-full`}
                                />
                            </Switch>

                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <button onClick={() => { prevStep() }} className='bg-green-500 w-full whitespace-nowrap text-white rounded-md p-2'>Geri</button>
                            <button onClick={() => { sumbitHandel() }} className='bg-green-500 w-full whitespace-nowrap text-white rounded-md p-2'>Kaydet</button>
                        </span>


                    </TabPanel>



                </TabPanels>
            </TabGroup>
        </Container>
    )
}

export default UpdateProduct