import React, { useEffect, useRef, useState } from 'react'
import Container from '../../Components/Container'
import { useCallback } from 'react'
const ProductHistory = () => {



  const [date, setDate] = useState()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState('')
  const [store, setStore] = useState('')
  const [cat, setCat] = useState([])
  const [stores, setStores] = useState([])
  const observer = useRef();
  const [page, setPage] = useState(1)



  useEffect(() => {

    const categories = new FormData()
    categories.append('action', 'select')

    fetch(process.env.REACT_APP_BASE_URL + 'categories.php', {
      method: 'POST',
      body: categories
    }).then(res => res.json()).then(data => {
      setCat(data)
    })

    const stores = new FormData()
    stores.append('action', 'select')
    fetch(process.env.REACT_APP_BASE_URL + 'store.php', {
      method: 'POST',
      body: stores
    }).then(res => res.json()).then(data => {
      setStores(data)
    })



  }, [])

  const lastCarElementRef = useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setPage(prevPage => prevPage + 1);

      }
    });
    if (node) observer.current.observe(node);
  }, [loading]);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) {
      return;
    }
    setPage(prevPage => prevPage + 1);
  }, [loading]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);




  const filterByCategories = (e) => {

    setCategory(e.target.value)

    if (e.target.value != 0) {
      const formdata = new FormData()
      formdata.append('action', 'selectByCategory')
      formdata.append('store', store)
      formdata.append('date', date)
      formdata.append('category', e.target.value)
      fetch(process.env.REACT_APP_BASE_URL + 'history.php', {
        method: 'POST',
        body: formdata
      }).then(res => res.json())
        .then(data => {

        })
      const allItems = document.querySelectorAll('[data-category]');

      allItems.forEach(item => {

        const d = item.dataset.category

        if (d != e.target.value) {
          item.classList.add('hidden')
        } else {
          item.classList.remove('hidden')

        }

      })
    }
  }
  const filterByStore = (e) => {

    setStore(e.target.value)

    if (e.target.value != 0) {


      const formdata = new FormData()
      formdata.append('action', 'selectByStore')
      formdata.append('store', e.target.value)
      formdata.append('date', date)
      fetch(process.env.REACT_APP_BASE_URL + 'history.php', {
        method: 'POST',
        body: formdata
      }).then(res => res.json())
        .then(data => {
          setData(data)
          setLoading(false)
        })

      const allItems = document.querySelectorAll('[data-store]');

      allItems.forEach(item => {

        const d = item.dataset.store

        if (d != e.target.value) {
          item.classList.add('hidden')
        } else {
          item.classList.remove('hidden')

        }

      })
    }

  }





  return (
    <Container>
      <div className='w-full flex items-start justify-between shadow-black/20 shadow-md  p-2 rounded-md '>
        <span className='w-full flex flex-col items-start justify-start gap-2'>
          <h1 className='lg:text-2xl text-base whitespace-nowrap text-black font-bold'>
            Ürün Geçimişi
          </h1>
        </span>
        <span className='w-full flex flex-col items-end justify-end gap-1'>
          <p className='text-lg font-bold'>
            {date}
          </p>
          <input type='date' value={date} className='' onChange={(e) => { setDate(e.target.value) }}></input>
        </span>
      </div>
      <div className='w-full flex items-start justify-start flex-col gap-6 shadow-black/20 shadow-md  p-2 rounded-md '>
        <span className='w-full flex items-center justify-between gap-4 '>
          <label htmlFor='name' className='text-gray-700 w-24'>Depo</label>
          <select value={store} onChange={(e) => { filterByStore(e) }} className='p-2 border w-full border-gray-300 rounded-md'>
            <option value={0}>Depo Seçiniz</option>
            {
              stores.map((store, index) => {
                return <option value={store.name} key={index}>{store.name}</option>
              })
            }
          </select>
        </span>
        {
          store != 0 && <span className='w-full flex items-center justify-between gap-4 '>
            <label htmlFor='categories' className='text-gray-700 w-24'>Kategori</label>
            <select value={category} onChange={(e) => { filterByCategories(e) }} className='p-2 border w-full border-gray-300 rounded-md'>
              <option>Kategori Seçiniz</option>
              {
                cat.map((category, index) => {
                  return <option value={category.name} key={index}>{category.name}</option>
                })
              }
            </select>
          </span>
        }

      </div>
      {
        data && <div className='w-full flex items-center justify-start bg-indigo-600 p-2 rounded-md '>
          <span className='w-full flex items-center justify-start text-white gap-2'>
            <p className='font-extrabold'>Adet:</p>
            <p>{data.length}</p>
          </span>
        </div>
      }

      <div id='container' className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 place-items-start'>
        {
          loading != true ? data.map((item, index) => {
            return (
              <div key={item.id} data-type={item.type} data-store={item.store} data-category={item.category} className='w-full bg-white p-4 rounded-md shadow-md'>

                <img src={item.image != null ? item.image : 'https://placehold.co/600x400'} className='w-full h-48 object-cover rounded-md' alt="" />
                <div className='w-full grid grid-cols-2 gap-4 p-4 text-center'>
                  <h1 className='text-sm capitalize font-semibold text-gray-800'>{item.name}</h1>
                  <h4 className='text-sm font-semibold text-gray-800'>Barkod :{item.barcode}</h4>
                  <p className='text-sm font-semibold text-gray-500'>{item.price} TL</p>
                  <p className='text-sm font-semibold text-gray-500'>{item.count} Adet</p>
                  <p className='text-sm font-semibold text-gray-500'>Kullanıcı :{item.user} </p>
                  <p className='text-sm font-semibold text-gray-500'>Tarih :{item.date} </p>
                </div>
                <div className='w-full flex flex-col items-start gap-2 justify-between mt-2'>

                </div>
              </div>
            )
          }) : <p>Yükleniyor</p>
        }
      </div>
      <span ref={lastCarElementRef}></span>
    </Container>
  )
}

export default ProductHistory