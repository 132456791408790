import React from 'react'
import Container from '../../Components/Container'
import DataTable from 'react-data-table-component';
import { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast';


const Category = () => {

    const [open, setOpen] = useState(false)
    const [name, setName] = useState('')
    const [data, setData] = useState([])
    const [update,setUpdate]= useState(false)
    const [id,setİd] = useState()


    const sumbitHandeler = (e) => {
        const formdata = new FormData()
        formdata.append('action', 'insert')
        formdata.append('name', name)
        fetch(process.env.REACT_APP_BASE_URL+'categories.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(data => {
            if(data.status != 400){
                toast.success(data.message)
                setTimeout(()=>{
                    window.location.reload()
                },1000)
                setOpen(false)
            }else{
                toast.error(data.message)
                setOpen(false)
            }
        }).catch(err => {
            console.log(err)
        }
        )
    }
    const toggleHandeler = (e) => {
        e.preventDefault()
        if (e.target.classList.contains('close')) {
            setOpen(false)
        }
    }

    useEffect(() => {
        const formdata = new FormData()
        formdata.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL+'categories.php', {
            method: 'POST',
            body: formdata
        })
            .then(res => res.json())
            .then(data => {
                setData(data)
            })
    }, [])


    const deleteHandel = (row) => {
        const formdata = new FormData()
        formdata.append('action', 'delete')
        formdata.append('id', row.id)
        formdata.append('cat', row.name)
        fetch(process.env.REACT_APP_BASE_URL+'categories.php', {

            method:'POST',
            body: formdata

        }).then(res => res.json()).then(data => {
            if(data.status != 400){
                toast.success(data.message)
                setTimeout(()=>{
                    window.location.reload()
                },1000)
                setOpen(false)
            }else{
                toast.error(data.message)
                setOpen(false)
            }
        }).catch(err => {
            console.log(err)
        }
        )
    }


    const updateHandel=(row)=>{
        setOpen(true)
        setUpdate(true)
        setName(row.name)
        setİd(row.id)
    }

    const fetchHandel=()=>{

        const formdata = new FormData()
        formdata.append('action','update')
        formdata.append('id',id)
        formdata.append('name',name)

        fetch(process.env.REACT_APP_BASE_URL+'categories.php',{
            method:'POST',
            body:formdata
        }).then(res=>res.json()).then(data=>{
            if(data.status != 400){
                toast.success(data.message)
                setOpen(false)
            }else{
                toast.error(data.message)
                setOpen(false)
                setTimeout(()=>{
                    window.location.reload()
                },1000)
            }
        }).catch(err=>{
            console.log(err)
        }
        )

    }

    const columns = [
        {
            name: 'No',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Kategori',
            selector: row => row.name,
            sortable: true,
        },
        
        {
            name: 'İşlem',
            selector: row => <div className='flex flex-col items-center justify-center gap-1 p-2'>
                <button onClick={()=>{updateHandel(row)}} className='bg-indigo-400 w-full text-white px-4 py-2 rounded-md'>Düzenle</button>
                <button onClick={(e) => {deleteHandel(row)}} className='bg-red-400 w-full text-white px-4 py-2 rounded-md'>Sil</button>
            </div>
        }

    ];


    return (
        <Container>
            <Toaster position='bottom-center'></Toaster>
            {
                open && <div onClick={(e) => { toggleHandeler(e) }} className='w-full p-4 lg:p-0 close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                    <div className='bg-white rounded-md p-4 w-full lg:w-1/2 gap-6 flex items-start justify-start flex-col'>
                        <h1 className='text-2xl font-semibold text-gray-700'>Yeni Kategori Ekle</h1>

                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='name' className='text-gray-700 whitespace-nowrap'>Kategori Adı</label>
                            <input onChange={(e) => { setName(e.target.value) }} value={name} type='text' placeholder='Kategori Adı' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <div className='w-full flex items-center justify-end'>
                            {
                                !update && <button onClick={() => { sumbitHandeler() }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Kaydet</button>
                            }
                            {
                                update && <button onClick={() => { fetchHandel()}} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Güncelle</button>
                            }
                        </div>
                    </div>
                </div>
            }
            <div className='w-full flex items-center justify-between relative'>
                <h1 className='text-2xl font-semibold text-gray-700'>Kategori Ayarları</h1>
                <button onClick={() => { setOpen(true) }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Yeni Kategori Ekle</button>
            </div>
            <DataTable data={data} columns={columns} pagination />
        </Container>
    )
}

export default Category