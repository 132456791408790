import React, { useEffect } from 'react'
import { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { Switch } from '@headlessui/react'


const Login = () => {


  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [admin, setAdmin] = useState(false)

  const navigate = useNavigate()

  const adminLogin = () => {
    const formdata = new FormData()
    formdata.append('action', 'adminLogin')
    formdata.append('name', name)
    formdata.append('password', password)
    fetch(process.env.REACT_APP_BASE_URL+'profile.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json()).then(data => {

      if (data.status != 200) {
        toast.error(data.message)
      }
      else {

        window.localStorage.setItem('token', data.data.token)
        window.localStorage.setItem('user', data.data.name)
        window.localStorage.setItem('role', data.data.role)

        navigate('/dashboard/panel')


      }
    })
  }


  const personelLogin = () => {
    
    const formdata = new FormData()
    formdata.append('action', 'personelLogin')
    formdata.append('name', name)
    formdata.append('password', password)
    fetch(process.env.REACT_APP_BASE_URL+'profile.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json()).then(data => {

      if (data.status != 200) {
        toast.error(data.message)
      }
      else {

        window.localStorage.setItem('token', data.data.token)
        window.localStorage.setItem('user', data.data.name)
        window.localStorage.setItem('role', data.data.role)

        if(data.data.role != 'admin'){
          navigate('/dashboard/userPanel')
        }else{
          navigate('/dashboard/panel')
        }


      }
    })


  }

  useEffect(() => {


    window.localStorage.clear()

  }, [])


  return (
    <div className='w-full flex flex-col items-center justify-center min-h-screen '>
      <Toaster position='bottom-center'></Toaster>
      
      <Switch
        checked={admin}
        onChange={setAdmin}
        className={`${admin ? 'bg-indigo-400' : 'bg-gray-200'
          } relative inline-flex items-center h-6 rounded-full w-11`}
      >
        
        <span
          className={`${admin ? 'translate-x-6' : 'translate-x-1'
            } inline-block w-4 h-4 transform bg-white rounded-full`}
        />
      </Switch>
      <div className='w-96 bg-white p-8 rounded-lg shadow-lg'>
        {
          admin ? <h2 className='text-2xl  w-full text-center font-bold mb-8'>Yönetici Girişi</h2> : <h2 className='text-2xl  w-full text-center font-bold mb-8'>Kullanıcı Girişi</h2>
        }
        <div className='flex flex-col space-y-4'>
          <input onChange={(e) => { setName(e.target.value) }} value={name} type='text' placeholder='Kullanıcı adı' className='p-2 border border-gray-300 rounded-lg' />
          <input onChange={(e) => { setPassword(e.target.value) }} value={password} type='password' placeholder='Şifre' className='p-2 border border-gray-300 rounded-lg' />
         {
           admin ? <button onClick={()=>{adminLogin()}} className='bg-indigo-400 text-white p-2 rounded-lg'>Giriş Yap</button> : <button onClick={()=>{personelLogin()}} className='bg-green-400 text-white p-2 rounded-lg'>Giriş Yap</button>
         }
        </div>
      </div>

    </div>
  )
}

export default Login