import React, { useEffect } from 'react'
import Container from '../../Components/Container'
import DataTable from 'react-data-table-component';
import { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';



const Users = () => {

    const [open, setOpen] = useState(false)
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [phone, setPhone] = useState('')
    const [role, setRole] = useState('')
    const [password, setPassword] = useState('')
    const [update, setUpdate] = useState(false)
    const [id, setId] = useState()
    const [data, setData] = useState([])


    useEffect(() => {
        const formdata = new FormData()
        formdata.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL+'users.php', {
            method: 'POST',
            body: formdata
        })
            .then(res => res.json())
            .then(data => {
                setData(data)
            })
    }, [])


    const toggleHandeler = (e) => {

        if (e.target.classList.contains('close')) {
            setOpen(false)
        }
    }

    const submitHandel = () => {
        const formdata = new FormData()
        formdata.append('action', 'insert')
        formdata.append('name', name)
        formdata.append('surname', surname)
        formdata.append('phone', phone)
        formdata.append('role', role)
        formdata.append('password', password)
        fetch(process.env.REACT_APP_BASE_URL+'users.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
        .then(data => {
            if(data.status != 400){
                toast.success(data.message)
            }else{
                toast.error(data.message)
            }
        })
        .catch(e => console.error(e))
    }


    const updateHandel = (row) => {
        setUpdate(true)
        setOpen(true)
        setId(row.id)
        setName(row.name)
        setSurname(row.surname)
        setPhone(row.phone)
        


    }



    const fetchHandel = () => {
        const formdata = new FormData()
        formdata.append('action', 'update')
        formdata.append('id', id)
        formdata.append('name', name)
        formdata.append('surname', surname)
        formdata.append('phone', phone)
        formdata.append('role', role)
        formdata.append('password', password)
        fetch(process.env.REACT_APP_BASE_URL+'users.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
        .then(data => {
            if(data.status != 400){
                toast.success(data.message)
            }else{
                toast.error(data.message)
            }
        })
        .catch(e => console.error(e))
    }


    const deleteHandel = (id) => {
        const formdata = new FormData()
        formdata.append('action', 'delete')
        formdata.append('id', id)
        fetch(process.env.REACT_APP_BASE_URL+'users.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
        .then(data => {
            if(data.status != 400){
                toast.success(data.message)
            }else{
                toast.error(data.message)
            }
        })
        .catch(e => console.error(e))
    }
    

    const columns = [
        {
            name: 'No',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'İsim',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Soyisim',
            selector: row => row.surname,
            sortable: true,
        },
        {
            name: 'Telefon',
            selector: row => row.phone,
            sortable: true,
        },
        {
            name: 'Rol',
            selector: row => row.role,
            sortable: true,
        },
        {
            name: 'İşlem',
            cell: row =>
                <div className='w-full flex items-center flex-col lg:flex-row py-2 justify-start gap-2'>
                    <button onClick={() => { updateHandel(row) }} className='bg-indigo-400 whitespace-nowrap w-full  hover:bg-indigo-600 inline-block cursor-pointer text-white px-4 py-2 rounded-md'>Düzenle</button>
                    <button onClick={()=>{deleteHandel(row.id)}} className='bg-red-400 whitespace-nowrap w-full hover:bg-red-600  inline-block cursor-pointer text-white px-4 py-2 rounded-md'>Sil</button>
                </div>,
            align: 'right',
        }
    ];



    return (
        <Container>

            {
                open && <div onClick={(e) => { toggleHandeler(e) }} className='w-full p-4 lg:p-0 close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                    <div className='bg-white rounded-md p-4 w-full lg:w-1/2 gap-6 flex items-start justify-start flex-col'>
                        {
                            update && <h1 className='text-2xl font-semibold text-gray-700'>Kişi düzenle</h1>
                        }
                        {
                            !update && <h1 className='text-2xl font-semibold text-gray-700'>Yeni Kişi Ekle</h1>
                        }
                        <span className='w-full flex items-center justify-between gap-4 '>

                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='name' className='text-gray-700 w-20'>Ad</label>
                            <input onChange={(e) => { setName(e.target.value) }} value={name} type='text' placeholder='Ad' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='surname' className='text-gray-700 w-20'>Soyad</label>
                            <input onChange={(e) => { setSurname(e.target.value) }} value={surname} type='text' placeholder='Soyad' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='phone' className='text-gray-700 w-20'>Telefon</label>
                            <input onChange={(e) => { setPhone(e.target.value) }} value={phone} type='tel' placeholder='Telefon' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='password' className='text-gray-700 w-20'>Şifre</label>
                            <input onChange={(e) => { setPassword(e.target.value) }} value={password} type='text' placeholder='Şifre' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='rol' className='text-gray-700 w-20'>Rol</label>
                            <select onChange={(e) => { setRole(e.target.value) }} value={role} name="rol" id="rol" className='p-2 border w-full border-gray-300 rounded-md'>
                                <option value={null}>Seçiniz</option>
                                <option value="admin">Yönetici</option>
                                <option value="user">Personel</option>
                            </select>
                        </span>
                        <div className='w-full flex items-center justify-end'>
                            {
                                !update && <button onClick={() => { submitHandel() }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Kaydet</button>
                            }
                            {
                                update && <button onClick={() => { fetchHandel() }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Güncelle</button>
                            }
                        </div>
                    </div>
                </div>
            }
            <div className='w-full flex items-center justify-end relative'>
                <button onClick={(e) => { setOpen(true) }} className='bg-indigo-400 hover:bg-indigo-600  inline-block cursor-pointer text-white px-4 py-2 rounded-md'>Yeni Kullanıcı Ekle</button>
            </div>
            <DataTable
                columns={columns}
                data={data}
                pagination

            />
        </Container>
    )
}

export default Users