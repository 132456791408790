import React, { useRef, useState } from 'react'
import Container from '../../Components/Container'
import { FaDiceFive } from "react-icons/fa";
import Barcode from 'react-barcode';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';




const Bar = () => {

  const barcodeRef = useRef(null)
  const [price, setPrice] = useState(0)
  const [barcode, setBarcode] = useState(0)
  const location = useLocation();

  useEffect(() => {
    setBarcode(location.state ? location.state.barcode : 0)
  }, [location])



  const randomBarcode = () => {


    let number = Math.floor(Math.random() * 9999999999)
    const formdata = new FormData
    formdata.append('action', 'chechBarcode')
    formdata.append('barcode', number)
    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
      method: 'POST',
      body: formdata
    })
      .then(res => res.json())
      .then(data => {
        if (data.status != 200) {
          toast.error(data.message)
        } else {
          setBarcode(data.barcode)
        }
      })


  }

  const handlePrint = () => {
    const printContents = barcodeRef.current.innerHTML;
    const originalContents = document.body.innerHTML;

    // Yeni bir pencere açıyoruz ve sadece barcode içeriğini yazdırıyoruz
    const printWindow = window.open('', '', 'height=500,width=800');
    printWindow.document.write('<html><head><title>Barcode</title>');
    // Yazdırma için özel stil ekliyoruz
    printWindow.document.write('<style>@media print { body { margin: 0; padding:0; display:flex; flex-direction: column; align-items: center; justify-content: center;  } } .box{}</style>');
    printWindow.document.write('</head><body class=`box`>');
    printWindow.document.write(printContents);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();
  };

  const keyHandel = (e) => {
    if (e.keyCode == 13) {
      setBarcode(e.target.value)
      const formdata = new FormData
      formdata.append('action', 'findByBarcode')
      formdata.append('barcode', e.target.value)
      fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
        method: 'POST',
        body: formdata
      })
        .then(res => res.json())
        .then(data => {
          if (data.status == 404) {
            toast.error('Ürün Bulunamadı')
          } else {
            setBarcode(data.data.barcode)
            setPrice(data.data.price)
          }
        })

    }
  }

  return (
    <Container>
      <Toaster position='top-center'></Toaster>
      <div className='w-full flex items-center justify-between gap-6' >
        <input type='text' onKeyDown={(e) => { keyHandel(e) }} onChange={(e) => { setBarcode(e.target.value) }} value={barcode} className='p-2 border w-full border-gray-300 rounded-md' />

        <button onClick={() => randomBarcode()} className='text-white bg-indigo-600 px-4 py-1.5 rounded-md' >
          <FaDiceFive size={32} ></FaDiceFive>
        </button >

      </div>
      <div ref={barcodeRef} className='w-full flex flex-col items-center justify-center h-52' >
        <Barcode format='' value={barcode} > </Barcode>
        {
          barcode && <p className='font-bold'>
            {price} TL
          </p>
        }
      </div>

      {
        < div className='w-full flex items-center justify-center'>
          <button className='p-4 whitespace-nowrap text-white bg-indigo-500 rounded-md ' onClick={() => { handlePrint() }}>
            Yazdır </button>
        </div >
      }
    </Container>
  )
}

export default Bar