import React, { useEffect, useState } from 'react'
import Container from '../../Components/Container'
import DataTable from 'react-data-table-component';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'



const Details = () => {

    const [loading, setLoading] = useState(true)

    const [cat, setCat] = useState()
    const [store, setStore] = useState()
    const [sales, setSales] = useState([])
    const [casualty, setCasualty] = useState([])
    const [r, setR] = useState([])
    const [all,setAll]=useState([])



    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const param1 = urlParams.get('c');
        const param2 = urlParams.get('s');
        setCat(param1)
        setStore(param2)

        const formdata = new FormData()
        formdata.append('action', 'selectReturn')
        formdata.append('store', param2)
        formdata.append('category', param1)
        fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                setR(data)
                setLoading(false)
            })


        const formdata2 = new FormData()
        formdata2.append('action', 'selectSell')
        formdata2.append('store', param2)
        formdata2.append('category', param1)
        fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
            method: 'POST',
            body: formdata2
        }).then(res => res.json())
            .then(data => {
                setSales(data)
                setLoading(false)
            })
        const formdata3 = new FormData()
        formdata3.append('action', 'selectCasualty')
        formdata3.append('store', param2)
        formdata3.append('category', param1)
        fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
            method: 'POST',
            body: formdata3
        }).then(res => res.json())
            .then(data => {
                setCasualty(data)
                setLoading(false)
            })
        const formdata4 = new FormData()
        formdata4.append('action', 'details')
        formdata4.append('store', param2)
        formdata4.append('category', param1)
        fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
            method: 'POST',
            body: formdata4
        }).then(res => res.json())
            .then(data => {
                setAll(data)
                setLoading(false)
            })
    }, [])


    const columns = [
        {
            name: 'No',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'İsim',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'barkod',
            selector: row => row.barcode,
            sortable: true,
        },
        {
            name: 'Adet',
            selector: row => row.count,
            sortable: true,
        },
        {
            name: 'Fiyat',
            selector: row => row.price,
            sortable: true,
        },
        {
            name: 'Maliyet',
            selector: row => row.cost,
            sortable: true,
        },


    ];


    const PanelBtn = ({ children }) => {
        return (
            <Tab as='span'>
                {({ hover, selected }) => (
                    <a className={selected ? 'bg-indigo-600 block text-sm  text-center lg:text-base px-6 py-2 text-white rounded-md' : 'bg-indigo-400  block text-center text-sm lg:text-base px-6 py-2 text-white rounded-md'}>{children}</a>
                )}
            </Tab>
        )
    }





    return (
        <Container>
            <div className='w-full flex items-center justify-between bg-indigo-500 rounded-md p-2'>
                <span className='w-full flex items-center justify-center flex-col gap-4 border-r border-slate-400'>
                    <h1 className='text-3xl text-white font-bold'>
                        Depo
                    </h1>
                    <hr className='h-px w-full bg-black'></hr>
                    <h1 className='text-2xl text-white font-semibold'>
                        {
                            store
                        }
                    </h1>
                </span>
                <span className='w-full flex items-center justify-center flex-col gap-4'>
                    <h1 className='text-3xl text-white font-bold' >
                        Kategori
                    </h1>
                    <hr className='h-px w-full bg-black'></hr>
                    <h1 className='text-2xl text-white font-semibold'>
                        {
                            cat
                        }
                    </h1>
                </span>
            </div>
            <TabGroup className='w-full flex items-start justify-start flex-col gap-6'>
                <TabList className='w-full flex items-center justify-between gap-6'>
                    <div className='w-full flex items-center justify-start gap-4'>
                        <PanelBtn>Satış</PanelBtn>
                        <PanelBtn>İade</PanelBtn>
                        <PanelBtn>Zaiat</PanelBtn>
                    </div>
                    <PanelBtn>Hepsi</PanelBtn>
                </TabList>

                <TabPanels className='w-full'>

                    <TabPanel className='w-full flex items-start flex-col justify-start h-auto gap-4 bg-slate-100 p-2 rounded-md'>
                        {
                            loading != true && sales ? <DataTable
                                columns={columns}
                                pagination={true}
                                data={sales}>
                            </DataTable> : <div className='w-full flex items-center justify-center h-screen'>
                                Yükleniyor
                            </div>
                        }
                    </TabPanel>
                    <TabPanel className='w-full flex items-start flex-col justify-start h-auto gap-4 bg-slate-100 p-2 rounded-md'>
                        {
                            loading != true && r ? <DataTable
                                columns={columns}
                                pagination={true}
                                data={r}>
                            </DataTable> : <div className='w-full flex items-center justify-center h-screen'>
                                Yükleniyor
                            </div>
                        }
                    </TabPanel>
                    <TabPanel className='w-full flex items-start flex-col justify-start h-auto gap-4 bg-slate-100 p-2 rounded-md'>
                        {
                            loading != true && casualty ? <DataTable
                                columns={columns}
                                pagination={true}
                                data={casualty}>
                            </DataTable> : <div className='w-full flex items-center justify-center h-screen'>
                                Yükleniyor
                            </div>
                        }
                    </TabPanel>
                    <TabPanel className='w-full flex items-start flex-col justify-start h-auto gap-4 bg-slate-100 p-2 rounded-md'>
                        {
                            loading != true && all ? <DataTable
                                columns={columns}
                                pagination={true}
                                data={all}>
                            </DataTable> : <div className='w-full flex items-center justify-center h-screen'>
                                Yükleniyor
                            </div>
                        }
                    </TabPanel>

                </TabPanels>
            </TabGroup>








        </Container>
    )
}

export default Details