import React from 'react'
import Container from '../../Components/Container'
import { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { json, Link } from 'react-router-dom'



const Panel = () => {

  const [data, setData] = useState([])
  const [text, setText] = useState('')
  const [price, setPrice] = useState(0)
  const [cost, setCost] = useState(0)
  const [count, setCount] = useState([])
  const [cats, setCats] = useState([]);
  const [open, setOpen] = useState(false)
  const [list, setList] = useState([])
  const [amount, setAmount] = useState()
  const [type, setType] = useState()
  const [totalCost, setTotalCost] = useState()
  const [retur, setReturn] = useState(0)




  useEffect(() => {
    const formdata = new FormData();
    formdata.append('action', 'select');
    formdata.append('date', new Date().toLocaleDateString())
    fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json())
      .then(data => {

        if (data.status != 400) {
          setData(data)
          setPrice(JSON.parse(data['price']).price)
          setReturn(JSON.parse(data['r']).price)
          setCost(JSON.parse(data['cost']).cost)
          setTotalCost(JSON.parse(data['totalCost']).totalCost)
          setList(JSON.parse(data['m']))
        }

      })

    const selectcount = new FormData()
    selectcount.append('action', 'selectCount')
    fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
      method: 'POST',
      body: selectcount
    }).then(res => res.json())
      .then(data => {
        setCount(data['store'])
        setCats(data['cat']);
      })

  }, [])

  const toggleHandeler = (e) => {
    e.preventDefault()
    if (e.target.classList.contains('close')) {
      setOpen(false)
    }
  }

  const listHandel = () => {


    if (!amount || !type) {
      toast.error('Bir tip veya miktar giriniz')
    }
    const start = new Date().toLocaleDateString()
    setList([...list, {
      'type': type,
      'amount': amount,
      'date': start
    }])


  }




  const sendList = () => {
    const start = new Date().toLocaleDateString()
    const formdata = new FormData()
    formdata.append('action', 'insert')
    formdata.append('data', JSON.stringify({
      'type': type,
      'text': text,
      'amount': amount,
      'date': start
    }))
    fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json())
      .then(data => {
        if (data.status != 200) {
          toast.error(data.message)
        } else {
          setList([...list, {
            'type': type,
            'text': text,
            'amount': amount,
            'date': start
          }])

          toast.success(data.message)

        }
      })
  }


  const deleteList = (item) => {
    const formdata = new FormData()
    formdata.append('action', 'deleteList')
    formdata.append('id', item.id)
    fetch(process.env.REACT_APP_BASE_URL + 'panel.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json())
      .then(data => {
        if (data.status != 200) {
          toast.error(data.message)
        } else {
          toast.success(data.message)
          setList(list.filter((i) => i.id != item.id))
        }
      })
  }

  const linkHandel = (p1, p2) => {

    var fullUrl = 'details/' + "?c=" + encodeURIComponent(p1) + "&s=" + encodeURIComponent(p2);
    window.location.href = fullUrl;
  }

  return (
    <Container>
      <Toaster position='top-center'></Toaster>
      {
        open && <div onClick={(e) => { toggleHandeler(e) }} className='fixed close z-50 w-full h-screen bg-black/50 top-0 left-0 py-12 px-6 flex flex-col items-start  justify-start gap-4 '>

          <div className='w-full bg-white rounded-md p-2 gap-4 flex flex-col items-start justify-start '>
            <span className='w-full flex flex-col items-center justify-center gap-4'>
              <span className='w-full flex items-center justify-center gap-4' >
                <input onChange={e => { setAmount(e.target.value) }} value={amount} placeholder='Tutar' type='number' className='p-2  border w-full border-gray-300 rounded-md' />
                <input onChange={e => { setText(e.target.value) }} value={text} placeholder='Açıklama' type='text' className='p-2  border w-full border-gray-300 rounded-md' />
              </span>
              <span className='w-full flex items-center justify-center gap-4'>
                <select onChange={e => { setType(e.target.value) }} className='p-2  border w-full border-gray-300 rounded-md'>
                  <option value={0}>Tür Seçiniz</option>
                  <option value={'Günlük'}>Günlük</option>
                  <option value={'Aylık'}>Aylık</option>
                  <option value={'Personel'}>Personel</option>
                  <option value={'Faturalar'}>Faturalar</option>
                  <option value={'Mültarif'}>Mültarif</option>
                </select>

                <button onClick={() => { sendList() }} className='w-52 bg-green-500 rounded-md p-2 text-white'>Kaydet</button>
              </span>
            </span>
            {
              list.length > 0 && <div className='w-full h-96 overflow-y-scroll  bg-white flex flex-col items-start justify-start gap-4 p-2'>
                {
                  list.map((item, index) => {
                    return (
                      <div className='w-full p-2  odd:bg-slate-300 flex items-center justify-between gap-6'>
                        <span className='flex flex-col items-start justify-start'>
                          <p>Maliyet</p>
                          <p>
                            {item.amount}  TL
                          </p>
                        </span>
                        <span className='flex flex-col items-start justify-start'>
                          <p>Tür</p>
                          <p>
                            {item.type}
                          </p>
                        </span>
                        <span className='flex flex-col items-start justify-start'>
                          <p>Açıklama</p>
                          <p>
                            {item.text}
                          </p>
                        </span>
                        <span className='flex flex-col items-start justify-start'>
                          <p>Tarih</p>
                          <p>
                            {item.date}
                          </p>
                        </span>
                        <span className='flex flex-col items-start justify-start'>
                          <button onClick={() => { deleteList(item) }} className='w-full flex items-center justify-center text-white bg-red-500 p-4 rounded-md'>Sil</button>
                        </span>
                      </div>
                    )

                  })
                }
              </div>
            }

          </div>



        </div>
      }
      <div className='w-full flex flex-col  items-center gap-4 justify-between place-items-center'>
        <Link to={'/dashboard/daily'} className='w-full h-40 shadow-sm shadow-black/50 flex-row-reverse bg-gray-100 p-2 rounded-md flex items-center justify-end px-6 gap-4'>
          <p className='text-gray-600 text-3xl font-bold'>
            Günlük Satış adeti
          </p>
          <p className='text-white text-2xl font-bold bg-indigo-400  w-24 aspect-square rounded-full flex items-center justify-center'>
            {
              data['count']
            }

          </p>
        </Link>
        <div className='w-full h-40 shadow-sm shadow-black/50 flex-row-reverse bg-gray-100 p-2 rounded-md flex items-center justify-end px-6 gap-4'>
          <p className='text-gray-600 text-3xl font-bold'>
            Günlük Kazancı
          </p>
          <p className='text-white text-xl font-bold bg-green-400  w-24 aspect-square rounded-full flex items-center justify-center'>
            {
              price != null ? parseInt(price - retur).toFixed(1) : 0
            }
            TL
          </p>
        </div>
        <div className='w-full h-40 shadow-sm shadow-black/50 flex-row-reverse bg-gray-100 p-2 rounded-md flex items-center justify-end px-6 gap-4'>
          <p className='text-gray-600 text-3xl font-bold'>
            Günlük Maliyeti
          </p>
          <p className='text-white text-2xl font-bold bg-red-400  w-24 aspect-square rounded-full flex items-center justify-center'>
            {
              cost
            }
            TL
          </p>
        </div>
        <div className='w-full h-40 shadow-sm shadow-black/50 flex-row-reverse bg-gray-100 p-2 rounded-md flex items-center justify-end px-6 gap-4'>
          <p className='text-gray-600 text-3xl font-bold'>
            Günlük Kar
          </p>
          <p className='text-white text-xl font-bold bg-yellow-400  w-24 aspect-square rounded-full flex items-center justify-center'>
            {
              parseInt(price - cost).toFixed(1)
            }
            TL
          </p>
        </div>
        <div onClick={() => { setOpen(true) }} className='w-full h-40 shadow-sm shadow-black/50 flex-row-reverse bg-gray-100 p-2 rounded-md flex items-center justify-end px-6 gap-4'>
          <p className='text-gray-600 text-3xl font-bold'>
            Günlük Gider
          </p>
          <p className='text-white text-2xl font-bold bg-red-800  w-24 aspect-square rounded-full flex items-center justify-center'>
            {
              totalCost == null ? '0' : totalCost
            }
            TL
          </p>
        </div>
        <h1 className='w-full  bg-gray-50 shadow-sm shadow-black/50 rounded-md p-2 text-2xl text-center  font-bold'>Stok Adetleri</h1>
        {
          count && count.map((subArray, index) => (
            subArray.map((item, subIndex) => (
              item.total != null ? <div key={subIndex} className='w-full h-auto shadow-sm shadow-black/50 flex-col-reverse bg-gray-100  rounded-md flex items-center justify-between px-6 gap-4'>
                <span className='w-full flex flex-col items-center justify-start h-auto gap-4'>
                  {
                    cats && cats.map((x, index) => {
                      const i = cats[index];
                      return (
                        <button onClick={() => linkHandel(Object.values(i[0])[1], item.store)} key={index} className={Object.values(i[0])[1] == null ? 'hidden' : 'w-full gap-6 text-white p-4 flex items-center justify-center h-full bg-indigo-600 rounded-md  flex-col '}>
                          <div className='w-full flex items-center justify-between gap-4 py-6 px-2 bg-white/20  rounded-md'>
                            <p className='text-lg font-bold'>
                              {Object.values(i[0])[0]} Adet
                            </p>
                            <p className='text-lg font-bold'>
                              Kategori {Object.values(i[0])[1]}
                            </p>

                          </div>
                          <div className='w-full flex items-center justify-between gap-4 py-6 bg-white/20 px-2 rounded-md'>
                            <p className='text-lg font-bold'>
                              Maliyet {Object.values(i[0])[2]} TL
                            </p>
                            <p className='text-lg font-bold'>
                              Fiyat  {Object.values(i[0])[3]} TL
                            </p>

                          </div>


                        </button>

                      )
                    }
                    )}


                </span>
                <span className='flex items-center gap-3  w-full justify-center'>
                  <span className='text-white  flex-col   w-full text-base rounded-md font-bold bg-red-400 p-2  h-20 aspect-square flex items-center justify-center'>
                    {
                      item.cost + ' TL'
                    }
                    <p className='text-xs whitespace-nowrap'>
                      Toplam Maliyeti
                    </p>
                  </span>
                  <span className='text-white  flex-col w-full   text-base rounded-md font-bold bg-green-400 p-2  h-20 aspect-square flex items-center justify-center'>
                    {
                      item.price + ' TL'
                    }
                    <p className='text-xs whitespace-nowrap'>
                      Toplam Satış Fiyatı
                    </p>
                  </span>
                </span>
                <span className='text-white  flex-col   w-full text-base rounded-md font-bold bg-orange-400 p-2  h-20 aspect-square flex items-center justify-center'>
                  {
                    item.total
                  }
                  <p className='text-xs whitespace-nowrap'>
                    Toplam Adet
                  </p>
                </span>
                <p className='text-gray-600 capitalize text-3xl font-bold'>
                  {item.store}
                </p>
              </div> : null

            ))))
        }


      </div>


    </Container>
  )
}

export default Panel