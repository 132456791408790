import React from 'react'

const Container = ({children}) => {
  return (
    <div className='w-full lg:py-12 py-4  mt-12 px-4 flex float-start items-start flex-col gap-3 min-h-screen'>
        {children}
    </div>
  )
}

export default Container