import React from 'react'
import Container from '../../Components/Container'
import { Link } from 'react-router-dom'
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { GiBanknote } from "react-icons/gi";
import { FaTrash } from "react-icons/fa";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { FaBarcode } from "react-icons/fa";



const UserPanel = () => {
  return (
    <Container>
      <Link className='w-full flex items-center justify-center gap-2 bg-green-500 rounded-md text-white min-h-40 text-3xl ' to={'/dashboard/sales'}>
      <FaRegMoneyBillAlt></FaRegMoneyBillAlt>
      Satış 
      </Link>
      <Link className='w-full flex items-center justify-center gap-2 bg-yellow-500 rounded-md text-white min-h-40 text-3xl ' to={'/dashboard/return'}>
      <GiBanknote></GiBanknote>
      İade</Link>
      <Link className='w-full flex items-center justify-center gap-2 bg-red-500 rounded-md text-white min-h-40 text-3xl ' to={'/dashboard/casualty'}>
      <FaTrash></FaTrash>
      Zaiat</Link>
      <Link className='w-full flex items-center justify-center gap-2 bg-indigo-500 rounded-md text-white min-h-40 text-3xl ' to={'/dashboard/products'}>
      <MdOutlineProductionQuantityLimits></MdOutlineProductionQuantityLimits>
      Ürünler</Link>
      <Link className='w-full flex items-center justify-center gap-2 bg-indigo-500 rounded-md text-white min-h-40 text-3xl ' to={'/dashboard/daily'}>
      <HiOutlineDocumentReport></HiOutlineDocumentReport>
      Günlük Durum</Link>
      <Link className='w-full flex items-center justify-center gap-2 bg-indigo-500 rounded-md text-white min-h-40 text-3xl ' to="/dashboard/barcode">
      <FaBarcode/>
      Barkod Oluştur</Link>
    </Container>
  )
}

export default UserPanel